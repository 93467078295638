import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Avatar from "@material-ui/core/Avatar"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const options = {
  renderNode: {
    "embedded-asset-block": node => {
      const alt = node.data.target.fields.title["en-US"]
      const url = node.data.target.fields.file["en-US"].url
      return <img alt={alt} src={url} />
    },
  },
}

export const query = graphql`
  query($slug: String!) {
    contentfulPostsVangtry(slug: { eq: $slug }) {
      title
      publishedDate(formatString: "MMMM, YYYY")
      featuredImage {
        fluid(maxWidth: 650, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      bodyContent {
        json
      }
    }
  }
`

const BlogPost = props => {
  return (
    <Layout>
      <SEO title={props.data.contentfulPostsVangtry.title} />

      <div className="container text-left page-wrapper">
        <div className="post-header mx-5">
          <Link to="/blog/">Regresar al blog</Link>
          <h1>{props.data.contentfulPostsVangtry.title}</h1>
          <div className="meta d-flex mt-4">
            <div className="mr-3">
              <Avatar>V</Avatar>
            </div>
            <div>
              <p className="mb-0">Equipo vangTry</p>
              <p>
                Publicado en {props.data.contentfulPostsVangtry.publishedDate}
              </p>
            </div>
          </div>
          {props.data.contentfulPostsVangtry.featuredImage && (
            <Img
              className="featured mt-4"
              fluid={props.data.contentfulPostsVangtry.featuredImage.fluid}
              alt={props.data.contentfulPostsVangtry.title}
            />
          )}
        </div>

        <div className="mt-5">
          <div className="post-wrapper">
            {documentToReactComponents(
              props.data.contentfulPostsVangtry.bodyContent.json,
              options
            )}
            <Link to="/blog/"> Regresar al blog</Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost
